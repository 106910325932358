import React, { useEffect, useState } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../../firebaseConfig'; // Adjust the import path as needed
import { Modal, Button } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './PopUpModal.less'; // Add styles as needed
import { useNavigate } from 'react-router-dom';

interface PopUpModalProps {
    isEnabled: boolean;
}

const PopUpModal: React.FC<PopUpModalProps> = ({ isEnabled }) => {
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [banners, setBanners] = useState<{ id: string; bannerPhotoUrl: string }[]>([]);

    // Fetch banners
    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'banner-photos'));
                const bannerData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    bannerPhotoUrl: doc.data().bannerPhotoUrl,
                }));
                setBanners(bannerData);
            } catch (error) {
                console.error('Error fetching banners:', error);
            }
        };

        fetchBanners();
    }, []);

    // Show modal if isEnabled is true
    useEffect(() => {
        if (isEnabled) {
            setIsModalVisible(true);
        }
    }, [isEnabled]);

    // Handle modal close
    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    // Handle confirm button click
    const handleConfirm = () => {
        setIsModalVisible(false);
        navigate("/shop");
    };

    return (
        <Modal
            title={null} // No title
            open={isModalVisible}
            onCancel={handleCloseModal}
            footer={null} // No footer
            closable={false} // Disable default close button
            width={800} // Adjust width as needed
            centered
            style={{ height: '80vh' }} // Set modal container height to 80vh
            className="popup-modal"
        >
            <div className="modal-content">
                <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={handleCloseModal}
                    className="close-button"
                />
                <Carousel
                    showArrows={true}
                    showThumbs={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={5000}
                >
                    {banners.map((banner) => (
                        <div key={banner.id}>
                            <img
                                src={banner.bannerPhotoUrl}
                                alt="Banner"
                                className="banner-image"
                            />
                        </div>
                    ))}
                </Carousel>
                <Button
                    type="primary"
                    onClick={handleConfirm}
                    className="confirm-button"
                >
                    Shop Now
                </Button>
            </div>
        </Modal>
    );
};

export default PopUpModal;