import React, { useState, useEffect } from "react";
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import ReactLazyPreload from '../utils/ReactLazyPreload';

import logo from '../assets/images/logo-black.svg';

import './Content.less';
import Header from "../components/header/header-top/Header";
import Hero from "../components/hero/Hero";
import HalalSection from "../components/halal-section/HalalSection";
import BenefitsComponent from "../components/benefits/Benefits";
import Footer from "../components/footer/Footer";
import WhatsappButton from "../components/whatsapp-button/WhatsappButton";
import SubHero from "../components/sub-hero/SubHero";
import LandingContent from "../components/landing-content/LandingContent";
import HeaderCollapse from "../components/header/header-collapse/HeaderCollapse";
import CallToAction from "../components/call-to-action-column/CallToAction";
import HeroMobile from "../components/hero/HeroMobile";
import PopUpModal from "../components/pop-up-modal/PopUpModal";

const Content = () => {
    const [isEnabled, setIsEnabled] = useState(false);

    // Fetch banner settings
    useEffect(() => {
        const fetchBannerSettings = async () => {
            try {
                const bannerSettingsDoc = await getDoc(doc(db, 'configurations', 'banner-settings'));
                if (bannerSettingsDoc.exists()) {
                    setIsEnabled(bannerSettingsDoc.data().isEnable);
                }
            } catch (error) {
                console.error('Error fetching banner settings:', error);
            }
        };

        fetchBannerSettings();
    }, []);

    return (
        <div className="content-container">
            <Header isSubPage={false} />
            <HeaderCollapse isSubPage={false} />
            <PopUpModal isEnabled={isEnabled} />
            <Hero />
            <HeroMobile />
            <SubHero />
            <LandingContent />
            <CallToAction />
            <HalalSection />
            <BenefitsComponent />
            <WhatsappButton />
            <Footer />
        </div>
    );
};

export default Content;